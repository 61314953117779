import { db } from "../indexedDB";
import store from "../store";
import axiosInstance from "../axiosInstance";
import { fetchAndStoreMyLeaveApplications } from "./myDataService";

const API_URL = process.env.VUE_APP_SERVICE_URL + "/api/leaves";
const STORE_NAME = "leave_applications";
const MY_DATA_STORE_NAME = "my_leave_applications";

export const fetchLeaveApplications = async (
  page,
  limit,
  fromDate,
  endDate,
  staffUuids,
  reviewStatus,
  type
) => {
  if (navigator.onLine) {
    const url =
      API_URL +
      `/v1/leave-applications/all?page=${page}&limit=${limit}&from_date=${fromDate}&end_date=${endDate}&staff_uuids=${staffUuids}&review_status=${reviewStatus}&type=${type}`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        const data = response.data;
        return data;
      }
    } catch (error) {
      store.commit("setError", { isError: true, error: error });
      console.error(`Failed to fetch ${STORE_NAME}:`, error);
    }
  } else {
    return [];
  }
};

export const getLeaveApplication = async (id) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/leave-applications/${id}`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        return response.data;
      }
    } catch (error) {
      console.error(`Failed to fetch ${STORE_NAME}:`, error);
      store.commit("setError", { isError: true, error: error });
    }
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot fetch leave application offline.`,
    });
  }
};

export const addLeaveApplication = async (item, isOthers = false) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/leave-applications/new`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        if (!isOthers) {
          await fetchAndStoreMyLeaveApplications();
        }
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to add ${STORE_NAME}: ${error}`,
      });

      console.error(`Failed to add ${STORE_NAME}:`, error);
    }
  } else if (!isOthers) {
    // Store the change for later
    await db.addOfflineChange({
      type: "addMyLeaveApplication",
      MY_DATA_STORE_NAME,
      item,
    });
    await db.addItem(MY_DATA_STORE_NAME, item);
    store.commit("setError", {
      isError: false,
      error: `Your changes are offline.`,
    });
    return "success";
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot create leave application offline.`,
    });
  }
};

export const editLeaveApplication = async (item, isOthers = false) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/leave-applications/edit`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        if (!isOthers) {
          await fetchAndStoreMyLeaveApplications();
        }
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to edit ${STORE_NAME}: ${error}`,
      });
      console.error(`Failed to edit ${STORE_NAME}:`, error);
    }
  } else if (!isOthers) {
    // Store the change for later
    await db.addOfflineChange({
      type: "editMyLeaveApplication",
      STORE_NAME,
      item,
    });
    await db.editItem(STORE_NAME, item.uuid, item);
    store.commit("setError", {
      isError: false,
      error: `Your changes are offline.`,
    });
    return "success";
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot create leave application offline.`,
    });
  }
};

export const deleteLeaveApplication = async (item, isOthers = false) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/leave-applications/delete`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        if (!isOthers) {
          await fetchAndStoreMyLeaveApplications();
        }
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to delete ${STORE_NAME}: ${error}`,
      });
      console.error(`Failed to delete ${STORE_NAME}:`, error);
    }
  } else if (!isOthers) {
    // Store the change for later
    await db.addOfflineChange({
      type: "deleteMyLeaveApplication",
      MY_DATA_STORE_NAME,
      item,
    });
    await db.deleteItem(MY_DATA_STORE_NAME, item.uuid);
    store.commit("setError", {
      isError: false,
      error: `Your changes are offline.`,
    });
    return "success";
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot delete leave application offline.`,
    });
  }
};

export const syncOfflineLeaveApplicationChanges = async () => {
  if (!navigator.onLine) return;

  const offlineChanges = await db.getOfflineChanges();
  for (const change of offlineChanges) {
    store.commit("startSync", true);
    try {
      if (change.type === "addMyLeaveApplication") {
        await addLeaveApplication(change.item.vehicle_uuid, change.item);
        await db.deleteOfflineChange(change.iDBkey);
      }
      if (change.type === "editMyLeaveApplication") {
        await editLeaveApplication(change.item.vehicle_uuid, change.item);
        await db.deleteOfflineChange(change.iDBkey);
      }
      if (change.type === "deleteMyLeaveApplication") {
        await deleteLeaveApplication(change.item.vehicle_uuid, change.item);
        await db.deleteOfflineChange(change.iDBkey);
      }
      store.dispatch("endSync");
    } catch (error) {
      console.error(`Failed to sync change for ${change.STORE_NAME}:`, error);
    }
  }
};

export const updateReviewStatus = async (item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/leave-applications/update-review-status`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to update Leave Application: ${error}`,
      });
      console.error(`Failed to update Leave Application:`, error);
    }
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot update Leave Application offline.`,
    });
  }
};
