import { db } from "../indexedDB";
import store from "../store";
import axiosInstance from "../axiosInstance";

const DATA_API_URL = process.env.VUE_APP_SERVICE_URL + "/api/data";

const MODULES_STORE_NAME = "modules";

export const fetchAndStorePermissionModules = async () => {
  if (navigator.onLine) {
    const url = DATA_API_URL + `/v1/permissions/all`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        const data = response.data;
        // Clear old data and store new data
        await db.clearStore(MODULES_STORE_NAME);
        data.forEach((item) => db.addItem(MODULES_STORE_NAME, item));
        return data;
      }
    } catch (error) {
      console.error(`Failed to fetch ${MODULES_STORE_NAME}:`, error);
      store.commit("setError", { isError: true, error: error });
    }
  } else {
    return await db.getAllItems(MODULES_STORE_NAME);
  }
};

const STAFF_PRELOAD_STORE_NAME = "staff_preload_data";

export const fetchAndStoreStaffData = async () => {
  if (navigator.onLine) {
    const url = DATA_API_URL + `/v1/staff/all`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        const data = response.data;
        // Clear old data and store new data
        await db.clearStore(STAFF_PRELOAD_STORE_NAME);
        data.forEach((item) => db.addItem(STAFF_PRELOAD_STORE_NAME, item));
        return data;
      }
    } catch (error) {
      console.error(`Failed to fetch ${STAFF_PRELOAD_STORE_NAME}:`, error);
      store.commit("setError", { isError: true, error: error });
    }
  } else {
    return await db.getAllItems(STAFF_PRELOAD_STORE_NAME);
  }
};

const ROLES_PRELOAD_STORE_NAME = "roles_preload_data";

export const fetchAndStoreRoleSData = async () => {
  if (navigator.onLine) {
    const url = DATA_API_URL + `/v1/roles/all`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        const data = response.data;
        // Clear old data and store new data
        await db.clearStore(ROLES_PRELOAD_STORE_NAME);
        data.forEach((item) => db.addItem(ROLES_PRELOAD_STORE_NAME, item));
        return data;
      }
    } catch (error) {
      console.error(`Failed to fetch ${ROLES_PRELOAD_STORE_NAME}:`, error);
      store.commit("setError", { isError: true, error: error });
    }
  } else {
    return await db.getAllItems(ROLES_PRELOAD_STORE_NAME);
  }
};

const VEHICLES_PRELOAD_STORE_NAME = "vehicles_preload_data";

export const fetchAndStoreVehiclesData = async () => {
  if (navigator.onLine) {
    const url = DATA_API_URL + `/v1/vehicles/all`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        const data = response.data;
        // Clear old data and store new data
        await db.clearStore(VEHICLES_PRELOAD_STORE_NAME);
        data.forEach((item) => db.addItem(VEHICLES_PRELOAD_STORE_NAME, item));
        return data;
      }
    } catch (error) {
      console.error(`Failed to fetch ${VEHICLES_PRELOAD_STORE_NAME}:`, error);
      store.commit("setError", { isError: true, error: error });
    }
  } else {
    return await db.getAllItems(VEHICLES_PRELOAD_STORE_NAME);
  }
};

const PROJECTS_PRELOAD_STORE_NAME = "projects_preload_data";

export const fetchAndStoreProjectsData = async () => {
  if (navigator.onLine) {
    const url = DATA_API_URL + `/v1/projects/all`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        const data = response.data;
        // Clear old data and store new data
        await db.clearStore(PROJECTS_PRELOAD_STORE_NAME);
        data.forEach((item) => db.addItem(PROJECTS_PRELOAD_STORE_NAME, item));
        return data;
      }
    } catch (error) {
      console.error(`Failed to fetch ${PROJECTS_PRELOAD_STORE_NAME}:`, error);
      store.commit("setError", { isError: true, error: error });
    }
  } else {
    return await db.getAllItems(PROJECTS_PRELOAD_STORE_NAME);
  }
};